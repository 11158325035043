<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="5" md="5" sm="12" xs="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_from"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_from')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                            required
                        ></b-form-input>
                      </b-form-group>
                </b-col>
                <b-col lg="5" md="5" sm="12" xs="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_to"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_to')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                            required
                        ></b-form-input>
                      </b-form-group>
                </b-col>
              <b-col lg="2" md="2" sm="12" xs="12">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('research_test.application') + ' ' + $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(created_at)="data">
                                            {{data.item.created_at | dateFormat}}
                                        </template>
                                        <template v-slot:cell(seed_name)="data">
                                            <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_name : info.seed_name_bn}},
                                            </span>
                                        </template>
                                        <template v-slot:cell(seed_variety_name)="data">
                                            <span>
                                                <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                <span v-if="info.seed_name_id === 6">
                                                    {{ info.seed_variety_others_name }}
                                                    {{ data.item.seed_infos.length !== index + 1 ? ',' : '' }}
                                                </span>
                                                <span v-else>
                                                    {{ $i18n.locale === 'en' ? info.seed_variety_name : info.seed_variety_name_bn}}
                                                    {{ data.item.seed_infos.length !== index + 1 ? ',' : '' }}
                                                </span>
                                                </span>
                                            </span>
                                        </template>
                                        <template v-slot:cell(seed_class_name)="data">
                                            <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_class_name : info.seed_class_name_bn}},
                                            </span>
                                        </template>
                                        <template v-slot:cell(testing_status)="data">
                                            <span v-if="data.item.testing_status == 'Without'">
                                                {{ $i18n.locale === 'en' ? data.item.testing_status : 'প্রত্যয়ন বহির্ভূত' }}
                                            </span>
                                            <span v-else-if="data.item.testing_status == 'With'">
                                                {{ $i18n.locale === 'en' ? data.item.testing_status : 'প্রত্যয়নের আওতাধীন' }}
                                            </span>
                                            <span v-else>{{ data.item.testing_status }}</span>
                                        </template>
                                        <template v-slot:cell(is_tested)="data">
                                            <span class="badge badge-warning" v-if="data.item.application_status === 9">{{ $i18n.locale == 'en' ? 'Testing Payment Pending' : 'টেস্টিং পেমেন্ট পেন্ডিং' }}</span>
                                            <span class="badge text-dark badge-warning" v-if="data.item.application_status === 11">{{ $i18n.locale == 'en' ? 'Testing Payment Pending' : 'টেস্টিং পেমেন্ট পেন্ডিং' }}</span>
                                            <span class="badge badge-primary" v-if="data.item.application_status === 12">{{ $i18n.locale == 'en' ? 'Testing Payment Paid' : 'টেস্টিং পেমেন্ট পেইড' }}</span>
                                            <span class="badge badge-secondary" v-if="data.item.application_status === 13">{{ $i18n.locale == 'en' ? 'Collection Schedule Confirmed' : 'কালেকশন শিডিউল কনফার্ম' }}</span>
                                            <span class="badge badge-dark" v-if="data.item.application_status === 14">{{ $i18n.locale == 'en' ? 'Collected' : 'সংগৃহীত' }}</span>
                                            <span class="badge text-dark badge-info" v-if="data.item.application_status === 15">{{ $i18n.locale == 'en' ? 'Registered for Lab Testing' : 'ল্যাব পরীক্ষার জন্য নিবন্ধিত হয়েছে' }}</span>
                                            <span class="badge badge-dark" v-if="data.item.application_status === 16">{{ $i18n.locale == 'en' ? 'Result Submitted' : 'ফলাফল সাবমিট করা হয়েছে' }} </span>
                                            <span class="badge text-dark badge-success" v-if="data.item.application_status === 17">{{ $i18n.locale == 'en' ? 'Result Provided' : 'ফলাফল প্রদানকৃত' }}</span>
                                            <!-- <span class="badge badge-danger" v-if="data.item.application_status === 17  || data.item.tag_status === 2">{{ $i18n.locale == 'en' ? 'Result and Tag Rejected' : 'ট্যাগ বাতিল' }}</span> -->
                                            <span class="badge text-dark badge-warning" v-if="data.item.application_status === 18">{{ $i18n.locale == 'en' ? 'Lot Confirmed' : 'লট কনফার্মড' }}</span>
                                            <span class="badge badge-primary" v-if="data.item.application_status === 19">{{ $i18n.locale == 'en' ? 'Tag Payment Paid' : 'ট্যাগ পেমেন্ট পেইড' }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                                    <b-button title="Edit" v-if="data.item.application_status === 16" v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="ScheduleAssign(data.item)"><i class="fas fa-pen-alt m-0"></i></b-button>
                                                    <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" title="Certificate Details" @click="view(data.item)"><i class="ri-file-mark-fill m-1"></i></b-button>
                                                    <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1" size="sm" title="View Details" @click="view(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-6" size="md" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <p>
                <FormV v-on:listenerChild="listenerChild" :general_info_id="general_info_id" />
            </p>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <DetailsV  :item="itemDetails"/>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle1" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <CertificateDetailsV  :item="itemDetails"/>
        </b-modal>
    </b-container>
</template>
<script>
   /* import DetailsV from './Details.vue' */
    import DetailsV from '../detailsView/Details'
    import CertificateDetailsV from '../tag-print/Details'
    import FormV from './Form.vue'
    import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
    import { approveApplication } from '../../api/routes'
    import ModalBaseMasterList from '@/mixins/modal-base-master-list'
    import { mapGetters } from 'vuex'
    import { core } from '@/config/pluginInit'
    import flatpickr from 'flatpickr'

    export default {
        mixins: [ModalBaseMasterList],
        components: {
            FormV,
            DetailsV,
            CertificateDetailsV
        },
        data () {
            return {
                ItemShow: false,
                datas: [],
                editItem: [],
                general_info_id: null,
                itemDetails: [],
                search: {
                    org_id: 0,
                    office_id: 0,
                    lab_id: 0,
                    status: 0,
                    from_date: '',
                    to_date: ''
                },
                statusList: [
                                {
                                value: 10,
                                text: 'Lot Offered',
                                text_en: 'Lot Offered',
                                text_bn: 'লট অফার'
                                },
                                {
                                value: 11,
                                text: 'Testing Payment Pending',
                                text_en: 'Testing Payment Pending',
                                text_bn: 'টেস্টিং পেমেন্ট পেন্ডিং'
                                },
                                {
                                value: 12,
                                text: 'Testing Payment Paid',
                                text_en: 'Testing Payment Paid',
                                text_bn: 'টেস্টিং পেমেন্ট পেইড'
                                },
                                {
                                value: 13,
                                text: 'Collection Schedule Confirm',
                                text_en: 'Collection Schedule Confirm',
                                text_bn: 'কালেকশন শিডিউল কনফার্ম'
                                },
                                {
                                value: 14,
                                text: 'Collected',
                                text_en: 'Collected',
                                text_bn: 'সংগৃহীত'
                                },
                                {
                                value: 15,
                                text: 'Registered for Lab Testing',
                                text_en: 'Registered for Lab Testing',
                                text_bn: 'ল্যাব পরীক্ষার জন্য নিবন্ধিত হয়েছে'
                                },
                                {
                                value: 16,
                                text: 'Result Submitted',
                                text_en: 'Result Submitted',
                                text_bn: 'ফলাফল সাবমিট করা হয়েছে'
                                },
                                {
                                value: 1,
                                text: 'Result Provided',
                                text_en: 'Result Provided',
                                text_bn: 'ফলাফল প্রদানকৃত'
                                },
                                {
                                value: 2,
                                text: 'Result and Tag Rejected',
                                text_en: 'Result and Tag Rejected',
                                text_bn: 'ট্যাগ বাতিল'
                                },
                                {
                                value: 18,
                                text: 'Lot Confirmed',
                                text_en: 'Lot Confirmed',
                                text_bn: 'লট কনফার্মড'
                                },
                                {
                                value: 19,
                                text: 'Tag Payment Paid',
                                text_en: 'Tag Payment Paid',
                                text_bn: 'ট্যাগ পেমেন্ট পেইড'
                                }
                            ]
                }
        },
        watch: {
        },
        computed: {
            ...mapGetters({
                authUser: 'Auth/authUser'
            }),
            formTitle () {
                return this.$t('certify_form.application_details')
            },
            formTitle1 () {
                return this.$t('seed_report.certificate_details')
            },
            columns () {
                const labels = [
                    { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                    { label: this.$t('globalTrans.application_date'), class: 'text-left' },
                    { label: this.$t('globalTrans.dealer_name_title'), class: 'text-left' },
                    { label: this.$t('globalTrans.seed_name_title'), class: 'text-left' },
                    { label: this.$t('globalTrans.variety_name_title'), class: 'text-center' },
                    { label: this.$t('globalTrans.class_name_title'), class: 'text-center' },
                    { label: this.$t('globalTrans.application_type'), class: 'text-center' },
                    { label: this.$t('globalTrans.status'), class: 'text-center' },
                    { label: this.$t('globalTrans.action'), class: 'text-center' }
                ]

                let keys = []

                if (this.$i18n.locale === 'bn') {
                    keys = [
                        { key: 'index' },
                        { key: 'created_at' },
                        { key: 'applicant_address.applicant_name_bn' },
                        { key: 'seed_name' },
                        { key: 'seed_variety_name' },
                        { key: 'seed_class_name' },
                        { key: 'testing_status' },
                        { key: 'is_tested' },
                        { key: 'action' }
                    ]
                } else {
                    keys = [
                        { key: 'index' },
                        { key: 'created_at' },
                        { key: 'applicant_address.applicant_name' },
                        { key: 'seed_name' },
                        { key: 'seed_variety_name' },
                        { key: 'seed_class_name' },
                        { key: 'testing_status' },
                        { key: 'is_tested' },
                        { key: 'action' }
                    ]
                }
                return labels.map((item, index) => {
                    return Object.assign(item, keys[index])
                })
            }
        },
        mounted () {
            core.index()
            flatpickr('.fromDate', {})
            if (this.authUser.role_id === 0) {
                this.search = Object.assign({}, this.search)
            }
            this.loadData()
        },
        created () {
            if (this.authUser.role_id === 1) {
            } else if (this.authUser.is_org_admin === 1) {
                this.search.org_id = this.authUser.org_id
            } else if (this.authUser.is_admin === true) {
                this.search.org_id = this.authUser.org_id
                if (this.authUser.office_type_id === 145 || this.authUser.office_type_id === 146 || this.authUser.office_type_id === 147) {
                    this.search.lab_id = this.authUser.office_id
                } else {
                    this.search.office_id = this.authUser.office_id
                }
            }
        },
        methods: {
            ScheduleAssign (data) {
                this.general_info_id = data.id
            },
            CheckRegister (type) {
                return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.registered_status') : this.$t('request_testing_tag_without_field_certification_application.un-registered_status')
            },
            async searchData () {
                this.loadData()
            },
            view (item) {
            this.itemDetails = item
            },
            listenerChild () {
                this.loadData()
            },
            loadData () {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                RestApi.getData(agriResearchServiceBaseUrl, approveApplication, params).then(response => {
                    if (response.success) {
                        this.$store.dispatch('setList', response.data.data)
                        this.paginationData(response.data)
                        const allData = this.$store.state.list
                        this.datas = JSON.parse(JSON.stringify(allData))
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                })
            },
            CheckStatus (data) {
                if (data.date_of_collection) {
                    return data.date_of_collection
                } else {
                    return this.$t('status_check.pending')
                }
            }
        }
    }
</script>
