<template>
  <div>
    <b-overlay :show="loadingState">
        <iq-card>
            <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                <template>
                    <ValidationProvider :name="$t('tag.title')" vid="approve" rules="required">
                        <b-form-group class="row" label-cols-sm="6" label-for="approve" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{$t('tag.title')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.approve" @change="checkStep(1)"   value="1">{{$t('tag.approve')}}</b-form-radio>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.approve" @change="checkStep(2)"   value="2">{{$t('tag.reject')}}</b-form-radio>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('tag.purity_test_r')" vid="purity_test_r" rules="required">
                        <b-form-group class="row" label-cols-sm="6" label-for="purity_test_r" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{$t('research_results.purity_test_r')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.purity_test_r" @change="checkStep(1)"   value="1">{{$t('tag.approve')}}</b-form-radio>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.purity_test_r" @change="checkStep(2)"   value="2">{{$t('tag.reject')}}</b-form-radio>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('tag.germination_test_r')" vid="germination_test_r" rules="required">
                        <b-form-group class="row" label-cols-sm="6" label-for="germination_test_r" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{$t('research_results.germination_test_r')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.germination_test_r" @change="checkStep(1)"   value="1">{{$t('tag.approve')}}</b-form-radio>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.germination_test_r" @change="checkStep(2)"   value="2">{{$t('tag.reject')}}</b-form-radio>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('tag.moisture_test_r')" vid="moisture_test_r" rules="required">
                        <b-form-group class="row" label-cols-sm="6" label-for="moisture_test_r" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{$t('research_results.moisture_test_r')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.moisture_test_r" @change="checkStep(1)"   value="1">{{$t('tag.approve')}}</b-form-radio>
                            <b-form-radio :state="errors[0] ? false : (valid ? true : null)" v-model="formData.moisture_test_r" @change="checkStep(2)"   value="2">{{$t('tag.reject')}}</b-form-radio>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    <!-- <ValidationProvider name="Remarks" vid="remarks" :rules="ru"> -->
                    <ValidationProvider name="Remarks" vid="remarks" :rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="remarks"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{$t('tag.remark')}}<span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                                plain
                                id="remarks"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="formData.remark"
                            >
                            </b-form-textarea>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </template>
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button type="button" variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                </div>
            </b-form>
        </ValidationObserver>
                </template>
        </iq-card>
    </b-overlay>
  </div>
</template>

<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../config/api_config'
import { sampleApproveSubmit } from '../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import flatpickr from 'flatpickr'
export default {
    props: ['general_info_id'],
    data () {
        return {
            saveBtnName: this.$t('globalTrans.save'),
            formData: {
                remark: '',
                approve: '',
                purity_test_r: '',
                germination_test_r: '',
                moisture_test_r: '',
                general_info_id: null
            },
            ru: 'required'
        }
    },
    computed: {
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mixins: [researchTestingModal, researchTestingHelper],
    async created () {
        this.formData.user_name = this.$store.state.Auth.authUser.name
        this.formData.user_name_bn = this.$store.state.Auth.authUser.name_bn
        this.formData.office_name = this.$store.state.Auth.authUser.office_detail.office_name
        this.formData.office_name_bn = this.$store.state.Auth.authUser.office_detail.office_name_bn
        this.formData.designation_name = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(this.$store.state.Auth.authUser.designation_id)).text_en
        this.formData.designation_name_bn = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(this.$store.state.Auth.authUser.designation_id)).text_bn
        this.formData.general_info_id = this.general_info_id
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
        checkStep (type) {
            if (type === 2) {
                this.ru = 'required'
            } else {
                this.ru = ''
            }
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }
            const result = await RestApi.postData(agriResearchServiceBaseUrl, sampleApproveSubmit, this.formData)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)
                this.$toast.success({
                    title: 'Success',
                    message: this.id ? 'Data updated successfully' : 'Data save successfully',
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-6')
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        }
    }
}
</script>

<style>

</style>
